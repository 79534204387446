import { template as template_19d48110a79546369b273e8fa79f91d2 } from "@ember/template-compiler";
const FKControlMenuContainer = template_19d48110a79546369b273e8fa79f91d2(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
