import { template as template_9aace4938558457bbd54d760257bdcce } from "@ember/template-compiler";
const FKLabel = template_9aace4938558457bbd54d760257bdcce(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
