import { template as template_8bb62a3b11b84a6e894dfb26b500c19b } from "@ember/template-compiler";
const WelcomeHeader = template_8bb62a3b11b84a6e894dfb26b500c19b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
